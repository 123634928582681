import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

const Legal = () => (
  <Layout>
    <SEO title="Legal" />
    <h1>Legal</h1>
  </Layout>
)

export default Legal
